import React, { useState } from 'react';

import styles from './index.module.scss';
import { DEFAULT_COVER } from '@/constants';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export interface IWorkflowItemProps {
  item: any;
  onSelected?: (item: any) => void;
  selected?: boolean;
}

export interface IWorkflowListProps {
  onlyPreview?: boolean;
  list: any[];
  onSelected?: (item: any) => void;
}

function WorkflowItemComp(props: IWorkflowItemProps) {
  const { item, onSelected, selected } = props;
  const { name, description, cover, ownerId, publicId } = item;
  const fileExt = (cover || DEFAULT_COVER)?.split('!')[0]?.split('?')[0].split('.').pop() || 'png';


  const handleSelect = () => {
    onSelected?.(item);
  };

  return <div key={publicId} className={selected ? styles.selectedWorkItem : styles.workItem} onClick={handleSelect}>
    <div
      className={styles.coverWrapper}>
      {
        ['webm', 'mp4'].includes(fileExt) ? <video src={cover} controls autoPlay loop /> 
        : <img src={cover || DEFAULT_COVER} alt={name} /> 
      }
      <div className={styles.coverMask}>
        
      </div>
    </div>
    <div className={styles.workItemBottom}>
      <div className={styles.ownerAvatar}>
        <img src={`/profile/avatar/${ownerId}`} />
      </div>
      <div className={styles.workItemDesc}>
        <div className={styles.workItemTitle}>{name}</div>
        <div className={styles.workItemDescription}>{description || '暂无描述'}</div>
      </div>
    </div>
  </div>;
}

function WorkflowListComp(props: IWorkflowListProps) {
  
  const { list, onSelected } = props;
  const [ current, setCurrent ] = useState<any>();

  const handleSelect = (item: any) => {
    setCurrent(item);
    onSelected?.(item);
  }

  return <div className={styles.workflowList}>
  {
    list?.length ? list.map(item => {
      return <WorkflowItemComp key={item.code} item={item} selected={current?.code === item.code} onSelected={handleSelect} />
    }) : null
  }
  </div>

}

export {
  WorkflowItemComp,
  WorkflowListComp
};
