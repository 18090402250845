import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Tabs, Upload, notification, message } from "antd";

import { app } from 'https://i.ablula.tech/awe/comfy/web/scripts/app.js';

import styles from './index.module.scss';
import { WorkflowItem } from "@/constants";
import { listWorkflows } from "@/services/Workflow";
import { WorkflowListComp } from "./components/workflow";
import { useGlobalStore } from "@/store";
import { useTranslation } from "react-i18next";
import { OssTOken } from "@/models/oss";
import { uploadFileToOSS } from "@/utils/file";

const CanvasComponent = () => {
  
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ t ] = useTranslation();
  // 创建一个 ref 来引用 div 容器
  const canvasContainerRef = useRef(null);
  const [ workflowList, setWorkflowList ] = useState<WorkflowItem[]>([]);
  
  const [ossToken, setOssToken] = useState<OssTOken>();
  const [messageApi, contextHolder] = message.useMessage();
  const [queueSize, setQueueSize] = useState(0);
  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      const initOssToken: OssTOken = (await tokenResponse.json()).data;
      setOssToken(initOssToken);
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi]);

  useEffect(() => {
    init();
  }, [init]);



  useEffect(() => {
    if (!ossToken || !canvasContainerRef?.current) return;
    const onUpload = async (file: File) => {
      console.log('outter upload: ', file, ossToken);
      try {
        return await uploadFileToOSS(file, ossToken);
      } catch (error) {
        message.error(`解析工作流失败: ${error.message}`);
      }
      return false;
    }
    const onQueueSizeChange = (size: number) => {
      setQueueSize(size);
    }
    if (!window.comfyApp) {
      app.setup(canvasContainerRef.current, { onUpload, onQueueSizeChange });
      window.comfyApp = app;
      window.comfyGraph = app.graph;
      window.app = app;
			window.graph = app.graph;
    } else {
      canvasContainerRef.current.appendChild(app.canvasEl)
      app.resizeCanvas();
      app.onUpload = onUpload;
      app.onQueueSizeChange = onQueueSizeChange;
    }
  }, [ossToken]); // 空依赖数组表示这个 effect 只在组件首次渲染时运行

  const fetchWorkflowList = async () => {
    try {
      const response = await listWorkflows({ isDefault: false});
      const _workflowList = response.data?.map(item => {
        return {
          ...item,
          contentTpl: JSON.parse(item?.contentTpl || '{}'),
          paramTpl: JSON.parse(item?.paramTpl || '{}'),
          outputTpl: JSON.parse(item?.outputTpl || '{}'),
        };
      });
      setWorkflowList(_workflowList || []);
    } catch (error) {
      notification.error({ message: '获取工作流列表失败', description: error.message });
    }
  };

  const handleWorkflowUpload = async (file: File) => {
    try {
      await comfyApp.handleFile(file);
    } catch (error) {
      message.error(`解析工作流失败: ${error.message}`);
    }
    return false;
  };
  
  useEffect(() => {
    fetchWorkflowList();
  }, []); // 空依赖数组表示这个 effect 只在组件首次渲染时运行

  const handleSelectWorkflow = (workflow: WorkflowItem) => {
    console.log(workflow);
    comfyApp.loadApiJson(workflow.contentTpl);
  }

  const handleQueuePrompt = () => {
    comfyApp.queuePrompt();
  }

  // 渲染一个空的 div 容器，canvas 会被插入其中
  return (
    <div className={styles.comfyui}>
      {contextHolder}
      <div className={styles.leftPanel}>
        <Tabs defaultActiveKey="1" tabPosition="left" type="card">
          <Tabs.TabPane className={styles.stickyTabPane} tab="工作流" key="1">
            <WorkflowListComp onSelected={handleSelectWorkflow} list={workflowList} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className={styles.mainPanel}>
        <div className={styles.actionPanel}>
          <div className={styles.action}></div>
          <div className={styles.action}></div>
          <div className={styles.action}>
            <Upload 
              disabled={!currentUser?.publicId}
              accept=".json,.jpeg,.png,.webp"
              itemRender={() => null}
              beforeUpload={handleWorkflowUpload} 
            >
              <Button type="dashed" disabled={!currentUser?.publicId}>{t('UploadWorkflow')}</Button>
            </Upload>
            <Button disabled>
              保存
            </Button>
            <Button type="primary" onClick={handleQueuePrompt}>
              执行
              <span>(排队{ queueSize })</span>
            </Button>
          </div>
        </div>
        <div className={styles.canvasPanel}>
          <div ref={canvasContainerRef} style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0,
            width: '100%',
            height: '100%',
          }}>
            {/* Canvas will be inserted here */}
          </div>
        </div>
      </div>
      <div className={styles.rightPanel}>

      </div>
    </div>
  );
};

export default CanvasComponent;