import { Select, Spin, Tabs } from "antd";


import styles from './index.module.scss';
import { useCallback, useEffect, useRef, useState } from "react";
import { listWorksPaged } from "@/services/Works";
import WorksMasonry from "./WorksMasonry";
import { useTranslation } from "react-i18next";
import { WorkflowDTO, listWorkflowCodes } from "@/services/Workflow";

const getTabItems = (works, t, workflowCodes, onWorkflowCodeChange) => [
  {
    key: 'AIWORKFLOW',
    label: `${t('AIWorkflow')}`,
    children: <div className={styles.worksMasonry}>
      <div className={styles.workflowSelection}>
        <Select 
          showSearch 
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          defaultValue={workflowCodes?.[0]?.code} 
          className={styles.workflowCodeSelect} 
          onChange={onWorkflowCodeChange!} 
          options={workflowCodes?.map(item => {
            return {
              label: item.name,
              value: item.code
            }
          })} 
        />
      </div>
      <WorksMasonry works={works} />
    </div>
  },
  {
    key: 'TXT2IMG',
    label: `${t('AIPaint')}-${t('TXT2IMG')}`,
    children: <div className={styles.worksMasonry}>
      <WorksMasonry works={works} />
    </div>
  },
  // {
  //   key: 'REMBG',
  //   label: `${t('ToolBox')}-${t('Rembg')}`,
  //   children: <div className={styles.worksMasonry}>
  //     <WorksMasonry works={works} />
  //   </div>
  // },
  // {
  //   key: 'RESAMPLE',
  //   label: `${t('ToolBox')}-${t('Resample')}`,
  //   children: <div className={styles.worksMasonry}>
  //     <WorksMasonry works={works} />
  //   </div>
  // },
];

const Works: React.FC = () => {
  const [ currentType, setCurrentType ] = useState('TXT2IMG');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentWorkflowCode, setCurrentWorkflowCode] = useState<string>();
  const [workflowCodes, setWorkflowCodes] = useState<WorkflowDTO[]>();
  const loader = useRef(null);
  const [ t ] = useTranslation();

  useEffect(() => {
    const fetchWorkflowCodes = async () => {
      try {
        const response = await listWorkflowCodes();
        setWorkflowCodes(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchWorkflowCodes();
  }, []);

  // 加载数据的函数
  const loadMoreData = useCallback(async () => {
    setIsLoading(true);
    try {
      let _type = currentType;
      if (_type === 'AIWORKFLOW') {
        _type = currentWorkflowCode || workflowCodes?.[0]?.code || currentType
      }

      const response = await listWorksPaged(_type, page, pageSize);
      const data = await response.data
      const { list, hasMore, total } = data;
      setData(prevData => [...prevData, ...list]);
      setHasMoreData(hasMore);
      if (hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, [currentType, page, pageSize, currentWorkflowCode, workflowCodes]);

  
  const handleObserver = useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMoreData && !isLoading) {
      loadMoreData();
    }
  }, [isLoading, hasMoreData, loadMoreData]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  // 在组件首次加载时加载第一批数据
  useEffect(() => {
    loadMoreData();
  }, [currentType, currentWorkflowCode]);
  
  const onTabChange = (key) => {
    setCurrentWorkflowCode('');
    setCurrentType(key);
    setData([]);
    setPage(1);
    setHasMoreData(true);
  }

  const onWorkflowCodeChange = (code) => {
    setData([]);
    setPage(1);
    setCurrentWorkflowCode(code);
  };

  return (
    <div className={styles.works}>
      <Tabs 
        items={getTabItems(data, t, workflowCodes, onWorkflowCodeChange)}
        onChange={onTabChange}
      >
      </Tabs>
      <div ref={loader}>
        {isLoading && <Spin><div></div></Spin>}
      </div>
    </div>
  );
}

export default Works;