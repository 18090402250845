
import { Button, Spin, Tabs, Upload, message, notification } from 'antd';
import style from './index.module.scss';
import { useEffect, useState } from 'react';
import WorkflowEditor from './editor';
import { WorkflowItem } from "@/constants";
import { listWorkflows } from '@/services/Workflow';
import WorkflowList from './list';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from '@/store';

export interface IWorkflowItemProps {
  item: WorkflowItem;
  onEdit?: (item?: WorkflowItem) => void;
  onlyPreview?: boolean;
}

const WorkflowHome: React.FC = () => {
  const [ workflowList, setWorkflowList ] = useState<WorkflowItem[]>([]);
  const [ defaultWorkflowList, setDefaultWorkflowList ] = useState<WorkflowItem[]>([]);
  const [ showEditor, setShowEditor ] = useState(false);
  const [ currentWorkflow, setCurrentWorkflow ] = useState<WorkflowItem>();
  const [ updateFlag, setUpdateFlag ] = useState<number>(1);
  const [ showEditorLoading, setShowEditorLoading ] = useState(false);
  const location = useLocation(); // 地址信息
  const navigate = useNavigate(); // 跳转
  const initialTab = location.hash.replace('#', '') || 'myWorkflow'; // 初始化时从URL的hash值获取Tab，如果不存在，则默认为tab1
  const [activeKey, setActiveKey] = useState(initialTab);
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ t ] = useTranslation();

  useEffect(() => {
    const fetchWorkflowList = async () => {
      try {
        const response = await listWorkflows({ isDefault: false});
        const workflowList = response.data?.map(item => {
          return {
            ...item,
            contentTpl: JSON.parse(item?.contentTpl || '{}'),
            paramTpl: JSON.parse(item?.paramTpl || '{}'),
            outputTpl: JSON.parse(item?.outputTpl || '{}'),
          };
        });
        setWorkflowList(workflowList || []);
      } catch (error) {
        notification.error({ message: '获取工作流列表失败', description: error.message });
      }
    };

    const fetchDefaultWorkflowList = async () => {
      try {
        const response = await listWorkflows({ isDefault: true });
        const workflowList = response.data?.map(item => {
          return {
            ...item,
            contentTpl: JSON.parse(item?.contentTpl || '{}'),
            paramTpl: JSON.parse(item?.paramTpl || '{}'),
            outputTpl: JSON.parse(item?.outputTpl || '{}'),
          };
        });
        setDefaultWorkflowList(workflowList);
      } catch (error) {
        notification.error({ message: '获取默认工作流列表失败', description: error.message });
      }
    };

    fetchWorkflowList();
    fetchDefaultWorkflowList();
  }, [updateFlag]);

  useEffect(() => {
    if (showEditorLoading) {
      setShowEditor(true)
      setShowEditorLoading(false);
    }
  }, [currentWorkflow, showEditorLoading]);

  const handleEdit = (item: WorkflowItem) => {
    setCurrentWorkflow(item);
    setShowEditorLoading(true);
  };

  const handleOk = () => {
    setShowEditor(false);
    setUpdateFlag(prev => prev + 1);
  };

  const handleCancel = () => {
    setShowEditor(false);
    setCurrentWorkflow(undefined);
  };

  const handleWorkflowUpload = async (file: File) => {
    try {
      await comfyApp.handleFile(file);
      const { output } = await comfyApp.graphToPrompt();
      setCurrentWorkflow({
        contentTpl: output
      });
      setShowEditor(true);
    } catch (error) {
      message.error(`解析工作流失败: ${error.message}`);
    }

    return false;
  }
  const handleTabChange = (key) => {
    navigate(`#${key}`);
    console.log('key', key);
    setActiveKey(key);
  };

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window.location.hash.replace('#', '');
      if (currentHash && currentHash !== activeKey) {
        setActiveKey(currentHash);
      }
    };

    // 监听hash变化
    window.addEventListener('hashchange', handleHashChange);

    // 组件卸载时解除监听
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [activeKey]);

  return <Spin spinning={showEditorLoading}><div className={style.WorkflowManagement}>
    <Tabs activeKey={activeKey} onChange={handleTabChange} defaultActiveKey="myWorkflow" tabBarExtraContent={<div className={style.action}>
      <Upload 
        disabled={!currentUser?.publicId}
        accept=".json,.jpeg,.png,.webp"
        itemRender={() => null}
        beforeUpload={handleWorkflowUpload} 
      >
        <Button disabled={!currentUser?.publicId} type="primary">{t('UploadWorkflow')}</Button>
      </Upload>
    </div>}>
      <Tabs.TabPane tab={<span className={style.tabTitle}>{t('MyWorkflow')}</span>} key="myWorkflow">
        <WorkflowList list={workflowList} onEdit={handleEdit} handleWorkflowUpload={handleWorkflowUpload} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={<span className={style.tabTitle}>{t('EditorPick')}</span>} key="collectedWorkflow">
        <WorkflowList onlyPreview list={defaultWorkflowList} handleWorkflowUpload={handleWorkflowUpload} />
      </Tabs.TabPane>
    </Tabs>
    <div>
      <WorkflowEditor 
        open={showEditor}
        workflow={currentWorkflow} 
        onOk={handleOk} 
        onCancel={handleCancel}
      />
    </div>
  </div></Spin>
};

export default WorkflowHome;