import { FieldData, WorkflowItem } from "@/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from './index.module.scss';
import { ParamNode } from "@/models/sd/comfy";
import { Button, Form, Input, Space, Spin, message, Image, FormInstance, ConfigProvider, Result, Modal, Pagination, Badge, Tabs, Empty } from "antd";
import { Profile } from "@/models/common/user";
import { SdTaskResult, SdTask } from "@/models/sd/SdFile";
import { getSdTask, listTaskResultsByTaskIds } from "@/services/SdFile";
import { getProfileData } from "@/services/user";
import { getWorkflowInviteCode, onDownload, saveWorkflowInviteCode, sleep } from "@/utils";
import { DownloadOutlined, SwapOutlined, RotateLeftOutlined, RotateRightOutlined, ZoomOutOutlined, ZoomInOutlined, LoadingOutlined, PlusOutlined, InboxOutlined, CloseOutlined, RollbackOutlined, DoubleRightOutlined, UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import { Loading } from "@/components/Loading";
import { runWorkflow } from "@/services/Workflow";
import { listWorks, listWorksPaged } from "@/services/Works";
import InputSlider from "@/components/InputSlider";
import CommonUploader from "@/components/CommonUploader";
import { RandomIcon } from "@/components/Icon/RandomIcon";
import { useGlobalStore } from "@/store";
import { useTranslation } from "react-i18next";
import ApiDocumentation from "./OpenAPI";
import SignAgreementDialog from "@/components/SignAgreementDialog";
import PaymentModal from "@/components/PaymentDialog";

export interface IParamRenderProps {
  param: Record<string, ParamNode>,
  form: FormInstance,
}

const UploadButton = (loading) => {
  return (
  <button style={{ border: 0, background: 'none', height: 222 }} type="button">
    {loading 
      ? <LoadingOutlined /> 
      : <>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag image to this area to upload</p>
      </>
    }
  </button>
)};

const PreviewComponent = (value, clear) => {
  return <div className={styles.imgWrapper}>
    <Image height={252} style={{objectFit: 'contain'}} src={value} /> 
    <Button className={styles.closeBtn} onClick={clear} type='text'><CloseOutlined /></Button>
  </div>
}

const SeedInput = (props) => {
  const { value, ...otherProps } = props;
  return <div className={styles.SeedInput}>
    <Input value={value} {...otherProps} />
    <Button onClick={() => {
      console.log('seed click')
      otherProps?.onChange?.(Math.floor(Math.random() * 100000000000000));
    }}><RandomIcon /></Button>
  </div>
};

const ParamRender = (props: IParamRenderProps) => {
  const { param, form } = props;
  
  if (!param) return null;

  return <>
      {Object.keys(param).filter((key) => {
        const node = param[key];
        return node.paramType !== 'TODO';
      }).map((key) => {
        const node = param[key];
        let compInstance = <Input  />;
        switch (node.paramType) {
          case 'Number':
            compInstance = <InputSlider 
              max={node.max || 2048}
              min={node.min || 512}
              
            />;
            break;
          case 'String':
            compInstance = <Input  />;
            break;
          case 'Image':
            compInstance = <CommonUploader
              imageStyle="!medium"
              accept={'.jpg,.png,.heic,.webp,.gif,.jpeg'}
              previewComponent={PreviewComponent}
              uploadButton={UploadButton}/>;
            break;
        }
        // 对一些 key 特殊处理
        if (node.paramKey === 'seed') {
          compInstance = <SeedInput form={form} />;
        } else if (node.paramKey === 'prompt' || node.paramKey === 'text') {
          compInstance = <Input.TextArea />;
        } else if (node.paramKey === 'batch_size') {
          compInstance = <InputSlider 
            max={node.max || 8}
            min={node.min || 1}
            
          />;
        } else if (node.paramKey === 'weight' || node.paramKey === 'strength') {
          compInstance = <InputSlider 
            max={node.max || 1}
            min={node.min || 0}
            step={0.01}
            
          />;
        } else if ([ 'top', 'bottom', 'left', 'right' ].includes(node.paramKey)) {
          compInstance = <InputSlider 
            max={node.max || 512}
            min={node.min || 0}
            
          />;
        }

        const notRequired = node.notRequired === 'Y';
        return <div key={key}  className={styles.paramItem}><Form.Item 
          key={key} 
          label={node.name || node.paramKey || node.key || key} 
          name={node.key || key}
          tooltip={node.description || node.paramKey}
          rules={[{ required: !notRequired, message: `请输入${node.name || node.paramKey}` }]}
        >
          {compInstance}
        </Form.Item></div>
      })}
  </>
}

const ResultPreview = (props) => {
  const { item, index, setPreviewIndex, previewIndex } = props;
  if (item.loading) {
    return <div key={index} className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
      <Spin />
    </div>
  }
  return (
    <div key={index} className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
      { 
        /[\u3400-\u9FBF]+/.test(item.results) 
          ? <span>{item.results}</span> 
          : item.results.endsWith('.webm') || item.results.endsWith('.mp4')
          ? <video style={{objectFit: 'contain'}} src={`https://i.ablula.tech/${item.results}`} autoPlay loop /> 
          : <img style={{objectFit: 'contain'}} src={`https://i.ablula.tech/${item.results}${(item.type === 'sora_001' || item.results?.endsWith('gif')) ? '' : '!xs'} `} />  }
    </div>
  )
}

const ResultPreviewGroup = (props) => {
  const { previewIndex, setPreviewIndex, sdTaskResults } = props;

  const result = sdTaskResults[previewIndex];
  const loading = result?.loading;
  const imagePath = result?.results;
  const audioUrl = result?.audioUrl;
  const videoUrl = result?.videoUrl;
  const styleSuffix = (result.type === 'sora_001' || imagePath?.endsWith('gif')) ? '' : '!wm';
  let content;
  if (videoUrl) {
    content = <video style={{objectFit: 'contain'}} src={`https://i.ablula.tech/${videoUrl}`} controls loop />;
  } else if (audioUrl) {
    content = <audio style={{objectFit: 'contain'}} src = {`https://i.ablula.tech/${audioUrl}`} controls loop ></audio>;
  } else if (loading) {
    content = <Loading visibility="visible" />;
  } else if (imagePath) {
    if (/[\u3400-\u9FBF]+/.test(imagePath)) {
      content = <span>{imagePath}</span>;
    } else if (imagePath.endsWith('.webm') || imagePath.endsWith('.mp4')) {
      content = <video style={{objectFit: 'contain'}} src={`https://i.ablula.tech/${imagePath}`} controls loop />;
    } else {
      content = <Image
        height="100%"
        style={{ maxHeight: '100%' }}
        className={styles.bigImageChild} 
        src={`https://i.ablula.tech/${imagePath}${styleSuffix}`} />;
    }
  }

  return (
    <Image.PreviewGroup
      preview={{
        current: previewIndex,
        onChange: setPreviewIndex,
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <DownloadOutlined onClick={() => {
              onDownload(sdTaskResults[previewIndex]?.publicId, `https://i.ablula.tech/${sdTaskResults[previewIndex]?.results}`);
            }} />
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
      items={sdTaskResults?.map?.(item => 
        `https://i.ablula.tech/${item.results}${(item.type === 'sora_001' || item.results?.endsWith('gif')) ? '' : '!wm'}`
      )}
    > { content }
    </Image.PreviewGroup>
  )
}

const WorkflowApp = (props) => {

  const { code } = useParams();
  const [ workflow, setWorkflow ] = useState<WorkflowItem>({});
  const [form] = Form.useForm();
  const [sdTaskResults, setSdTaskResults] = useState<SdTaskResult[]>([]);
  const [generating, setGenerating] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [unfinishedTasks, setUnfinishedTasks] = useState<SdTask[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [previewIndex, setPreviewIndex] = useState(0);
  const [formFields, setFormFields] = useState<FieldData[]>();
  const [profileData, setProfileData] = useState<Profile>({});
  const currentUser = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const showSignAgreementModal = useGlobalStore((state) => state.showSignAgreementModal);
  const [ noPermission, setNoPermission ] = useState(false);
  const [ needInviteCode, setNeedInviteCode ] = useState(false);
  const [ inviteCode, setInviteCode ] = useState('');
  const [ total, setTotal ] = useState(0);
  const [ pageNo, setPageNo ] = useState(1);
  const [ pageSize, setPageSize ] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [t] = useTranslation();
  const loader = useRef(null);
  const miniLoader = useRef(null);
  const navigate = useNavigate();
  const [topHeight, setTopHeight] = useState(innerHeight * .7); // 初始顶部区域高度
  const [dragging, setDragging] = useState(false);
  const [dragWarning, setDragWarning] = useState(false);
  const [isMobilePortrait, setIsMobilePortrait] = useState(false);

  const isSignAgreementModalOpen = useGlobalStore((state) => state.isSignAgreementModalOpen);
  const hideSignAgreementModal = useGlobalStore((state) => state.hideSignAgreementModal);
  const showPaymentModal = useGlobalStore((state) => state.showPaymentModal);
  const isPaymentModalOpen = useGlobalStore((state) => state.isPaymentModalOpen);
  const hidePaymentModal = useGlobalStore((state) => state.hidePaymentModal);

  useEffect(() => {
    function handleResize() {
      // 获取屏幕宽度和高度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight;

      // 判断是否是移动端并且处于竖屏状态
      setIsMobilePortrait(screenWidth < screenHeight && /Mobi|Android/i.test(navigator.userAgent));
    }

    // 添加窗口大小改变事件监听器
    window.addEventListener('resize', handleResize);

    // 初始状态下也触发一次handleResize以更新状态
    handleResize();

    // 清理函数，在组件卸载时移除事件监听器
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // 开始拖拽事件
  const startDragging = (e) => {
    e.preventDefault();
    setDragging(true);
    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', stopDragging);
  };

  // 拖拽事件
  const onDrag = (e) => {
    if (e.clientY < innerHeight * .3 || e.clientY > innerHeight * .7) {
      setDragWarning(true);
      return;
    }
    setDragWarning(false);
    setTopHeight(e.clientY);
  };

  // 停止拖拽事件
  const stopDragging = () => {
    setDragging(false);
    window.removeEventListener('mousemove', onDrag);
    window.removeEventListener('mouseup', stopDragging);
  };

  const handleGenerate = async (ignoreSignAgreement) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return;
    }
    if (!ignoreSignAgreement && !currentUser?.signAgreementAt) {
      showSignAgreementModal()
      return;
    }
    // 判断 M 币是否足够，不足则弹出支付框
    console.log('balance: ', profileData?.mcoinAccount?.balance);
    console.log('workflow cost: ', (workflow?.costByRecord || workflow?.costByCount));
    const balance = +(profileData?.mcoinAccount?.balance || 0);
    const workflowCost = +(workflow?.costByRecord || workflow?.costByCount);
    if (balance < workflowCost) {
      showPaymentModal();
      return;
    }

    try {
      setGenerating(true);
      form.validateFields();
      const updates = form.getFieldsValue();
      const param = {
        workflowCode: code,
        params: {
          ...updates
        }
      };
      const res = await runWorkflow(param, inviteCode);

      if (!res?.success || !res.data?.publicId) {
        messageApi.error(`${t('generateFailed')}: ${res?.message}`);
        return;
      }

      let initLoadingCount = 0;
      initLoadingCount += (updates?.batch_size || 1);
      if (initLoadingCount > 0) {
        for (let i = 0; i < initLoadingCount; i++) {
          sdTaskResults.unshift({
            loading: true,
          })
        }
      }

      const profileResp = await getProfileData();
      setProfileData(profileResp.data);
      setUnfinishedTasks([res.data]);
    } catch (e) {
      messageApi.error(e.message);
    }
    setGenerating(false);
  }

  useEffect(() => {
    if (!currentUser?.publicId) {
      return;
    }
    const init = async () => {
      const inviteCode = await getWorkflowInviteCode(code);
      const profileResp = await getProfileData();
      const taskResultsResp = await listWorksPaged(code, pageNo, pageSize);
      const unfinishedTasksResp = await listWorks({ type: code, unfinished: true});

      if (profileResp?.data) {
        setProfileData(profileResp.data);
      }

      const imageDataList: SdTaskResult[] = [];
      const { list, hasMore, total } = taskResultsResp?.data || {};
      if (list?.length) {
        setHasMoreData(hasMore);
        if (hasMore) {
          setPageNo(prevPage => prevPage + 1);
        }
        imageDataList.push(...(list));
      }

      if (unfinishedTasksResp.data) {
        let initLoadingCount = 0;
        unfinishedTasksResp.data.forEach((item: SdTask) => {
          initLoadingCount += (item.params?.batch_size || 1);
        });
        if (initLoadingCount > 0) {
          for (let i = 0; i < initLoadingCount; i++) {
            imageDataList.unshift({
              loading: true,
            })
          }
          setUnfinishedTasks(unfinishedTasksResp.data);
        }
      }
      setInviteCode(inviteCode as string)
      setSdTaskResults(imageDataList);
      setGlobalLoading(false);
      setTotal(taskResultsResp?.data?.total || 0);
    }
    init();
  }, [currentUser]);

  useEffect(() => {
    if (!unfinishedTasks?.length) return;
    const loopFetchResult = async () => {
      let hasResult = false;
      while (!hasResult) {
        unfinishedTasks.forEach(async item => {
          const taskResp = await getSdTask(item.publicId as string);
          if (taskResp?.data?.stage === 'FINISHED') {
            hasResult = true;
          }
        });
        if (!hasResult) {
          await sleep(2000);
        }
      }
      const taskIds = unfinishedTasks.map(item => item.publicId);
      const taskResultsResp = await listTaskResultsByTaskIds(taskIds);

      if (taskResultsResp?.data?.length) {
        const newSdResult = sdTaskResults?.filter?.(item => !item.loading);
        newSdResult.unshift(...(taskResultsResp.data));
        setUnfinishedTasks([]);
        setSdTaskResults(newSdResult);
      }
      setTimeout(() => {
        setGenerating(false);
        setPreviewIndex(0);
      });
    }
    loopFetchResult();
  }, [sdTaskResults, unfinishedTasks]);

  useEffect(() => {
    const currentTaskResult = sdTaskResults?.[previewIndex];
    if (!currentTaskResult) return;
    setFormFields([]);
    const params = currentTaskResult?.params?.params;
    if (!params) return;
    const parsedFieldDataList: FieldData[] = [
      ...Object.keys(params).map((key) => {
        return {
          name: key,
          value: params[key]
        }
      })
    ];
    setFormFields(parsedFieldDataList);
  }, [previewIndex, sdTaskResults]);

  useEffect(() => {
    const fetchWorkflow = async () => {
      const inviteCode = await getWorkflowInviteCode(code);
      const response = await fetch(`/open/api/v1/workflows/${code}?inviteCode=${inviteCode}`);
      const data = await response.json();
      if (!data.success) {
        message.error(`获取工作流失败: ${data.message}`);
        if (data.code === 403) {
          setNoPermission(true);
        } else if (data.code === 1004031) {
          setNeedInviteCode(true);
        } else if (data.code === 404) {
          navigate('/404');
        }
        return;
      }
      const workflow = data.data;
      const paramTpl = JSON.parse(workflow?.paramTpl || '{}');
      setWorkflow({
        ...workflow,
        contentTpl: JSON.parse(workflow?.contentTpl || '{}'),
        paramTpl,
        outputTpl: JSON.parse(workflow?.outputTpl || '{}'),
      });
      setFormFields(Object.keys(paramTpl).filter((key) => {
        const node = paramTpl[key];
        return node.paramType !== 'TODO';
      }).map((key) => {
        const node = paramTpl[key];
        return {
          name: node.key || key,
          value: node.defaultValue
        }
      }));
    };
    fetchWorkflow();
  }, [code, currentUser]);

  const backToWorkflow = () => {
    navigate('/workflow');
  };

  const handleSaveInviteCode = async (values) => {
    saveWorkflowInviteCode(code, values.inviteCode);
    location.reload();
  }

  const loadMoreData = useCallback(async () => {
    if (!currentUser?.publicId) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await listWorksPaged(code, pageNo, pageSize);
      const data = await response.data
      const { list, hasMore, total } = data;
      setSdTaskResults(prevData => [...prevData, ...list]);
      setHasMoreData(hasMore);
      if (hasMore) {
        setPageNo(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, [code, pageNo, pageSize]);

  const handleObserver = useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMoreData && !isLoading) {
      loadMoreData();
    }
  }, [isLoading, hasMoreData, loadMoreData]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
    if (miniLoader.current) {
      observer.observe(miniLoader.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  const handleToggleHistory = () => {
    if (topHeight < (innerHeight * .7 - 10)) {
      setTopHeight(innerHeight * .7);
    } else {
      setTopHeight(innerHeight * .3);
    }
  }

  if (noPermission || needInviteCode) {
    return <>
      <Modal title="请输入邀请码" open={needInviteCode} closable={false} closeIcon={null} footer={null}>
        <Form layout="inline" onFieldsChange={(changedFields) => {
          setInviteCode(changedFields[0].value);
        }} fields={[{
          name: 'inviteCode',
          value: inviteCode
        }]} onFinish={handleSaveInviteCode}>
          <div style={{ display: 'flex', width: '100%' }}>
            <Form.Item style={{ flex: 'auto' }} rules={[{ required: true }]} label="" name="inviteCode">
              <Input />
            </Form.Item>
            <div className={styles.submit}>
              <Button htmlType="submit" disabled={!inviteCode} className={styles.submitButton} type="primary">确定</Button>
            </div>
          </div>
        </Form>
      </Modal>
      <Result
        status="403"
        title={<div className={styles.errorTitle}>403 无权限访问</div>}
        subTitle={<div className={styles.errorSubTitle}>{`Sorry, you are not authorized to access workflow ${code}.`}</div>}
        extra={<Button type="primary" onClick={() => {
          navigate('/');
        }}>Back Home</Button>}
      />
    </>;
  }
  
  return (
    <><Spin spinning={globalLoading}><div className={styles.container}>
      {contextHolder}
      <div className={styles.content}>
        <Form className={styles.formWrapper} layout="vertical" form={form} fields={formFields} >
          <div className={styles.contentSection}>
            <Tabs type="card" tabPosition={isMobilePortrait ? "top" : "left"} defaultActiveKey="1">
              <Tabs.TabPane tab={t('workflow.app.playground')} key="1">
                <div className={styles.bottomParamArea}>
                  <div className={styles.leftArea}>
                    <div className={styles.topParamArea}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <span style={{ fontSize: 24 }}>{workflow?.name}</span>
                        <span>{workflow?.description || 'no description'}</span>
                      </div>
                      <div className={styles.prompts}>
                        <ParamRender param={workflow?.paramTpl} form={form} />
                      </div>
                      <div className={styles.actions} >
                      <Badge.Ribbon className={styles.actions} text={
                        t('Discount')
                      } color="#fd12c5">
                        <Button 
                          type="primary" 
                          htmlType="submit" 
                          size="large"
                          className={styles.generateBtn} 
                          loading={generating}
                          onClick={handleGenerate?.bind(null, false)}
                        >
                          { generating ? t('generating') : t('generate') }
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <span style={{ fontSize: 8 }}> {t('workflow.app.cost')} { (workflow?.costByRecord || workflow?.costByCount)?.replace?.('.00', '') } {t('workflow.app.MCoin')}</span>
                            <span style={{ fontSize: 8 }}>({t('workflow.app.left')} { profileData?.mcoinAccount?.balance?.replace?.('.00', '') } {t('workflow.app.MCoin')})</span>
                          </div>
                        </Button>
                      </Badge.Ribbon>
                      </div>
                    </div>
                    <div className={styles.miniImageGallery}>
                      <div className={styles.bigImage}>
                        {sdTaskResults?.length ? <ResultPreviewGroup
                          previewIndex={previewIndex}
                          setPreviewIndex={setPreviewIndex}
                          sdTaskResults={sdTaskResults}
                        /> : null}
                      </div>
                      <div className={styles.imageListContainer}>
                        { sdTaskResults?.length ? sdTaskResults.map((item, index) => <ResultPreview 
                          key={index} 
                          item={item} 
                          index={index} 
                          setPreviewIndex={setPreviewIndex} 
                          previewIndex={previewIndex} />)
                        : null }
                        <div ref={miniLoader}>
                          {isLoading && <Spin><div></div></Spin>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.divider} />
                  <div className={styles.rightArea}>
                    <div className={styles.bigImage} style={{ 
                      height: `${topHeight - 88}px`, 
                      transition: dragging ? 'none' : 'height 1s'
                    }}>
                      {sdTaskResults?.length ? <ResultPreviewGroup
                        previewIndex={previewIndex}
                        setPreviewIndex={setPreviewIndex}
                        sdTaskResults={sdTaskResults}
                      /> : null}
                    </div>
                    <div 
                      className={styles.dragHandle}
                      onMouseDown={startDragging}
                      style={{ border: dragging && dragWarning ? '1px dashed red': '1px dashed #ccc' }}
                    >
                      <div className={styles.quickHandle}>
                        <Button type="text" onClick={handleToggleHistory}>
                          {
                            topHeight < (.7 * innerHeight - 10)
                              ? <DownCircleOutlined style={{ fontSize: 20, color: 'gray', background: 'white', borderRadius: '50%' }} />
                              : <UpCircleOutlined style={{ fontSize: 20, color: 'gray', background: 'white', borderRadius: '50%' }} />
                          }
                        </Button>
                      </div>
                    </div>
                    <div className={styles.imageListContainer} style={{ height: `calc(100vh - 40px - ${topHeight}px)` }}>
                      { sdTaskResults?.length ? sdTaskResults.map((item, index) => 
                        <ResultPreview 
                          key={index} 
                          item={item} 
                          index={index} 
                          setPreviewIndex={setPreviewIndex} 
                          previewIndex={previewIndex} />) 
                        : null 
                      }
                      <div ref={loader}>
                        {isLoading && <Spin><div></div></Spin>}
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t("workflow.app.api")}>
                <div className={styles.apiArea}>
                  <ApiDocumentation workflow={workflow} APITokenDescription={t('OpenAPI.APITokenDescription')} />
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Form>
      </div>
    </div>
    </Spin>

    <SignAgreementDialog
      onCancel={hideSignAgreementModal}
      open={isSignAgreementModalOpen}
      onOk={handleGenerate?.bind(null, true)}
    />
    <PaymentModal
      onCancel={hidePaymentModal}
      open={isPaymentModalOpen}
    />
    <Modal open={needInviteCode}>
      <Form fields={[{
        name: 'inviteCode',
        value: inviteCode
      }]} onFinish={handleSaveInviteCode}>
        <Form.Item rules={[{ required: true }]} label="请输入邀请码" name="inviteCode">
          <Input />
        </Form.Item>
        <div className={styles.submit}>
          <Button disabled={!inviteCode} className={styles.submitButton} type="primary">确定</Button>
        </div>
      </Form>
    </Modal>
    </>
  );
};

export default WorkflowApp;
