import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import "@/plugins/i18n";
import ThemeConfig from "@/config/themeConfig";
import App from './App';
import './global.css';


const basename = document.getElementsByTagName('base')[0]?.getAttribute('href') || '';
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.Fragment>
    <BrowserRouter basename={basename}>
      <ConfigProvider theme={ThemeConfig.theme}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </React.Fragment>,
);
