import ImgCrop from 'antd-img-crop'
import { OptionProps } from "antd/es/select";
import { useCallback, useEffect, useState } from "react";
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Cascader, CascaderProps, Form, Input, Modal, ModalProps, Select, Switch, Upload, UploadProps, message } from "antd";

import { api } from "@/scripts/api.js";
import { FieldData, WorkflowItem } from "@/constants";
import { ComfyNode, NodeDef, NodeDefs, ParamNode } from "@/models/sd/comfy";

import style from './index.module.scss';
import { OssTOken } from "@/models/oss";
import moment from "moment";
import { DefaultOptionType } from 'antd/es/cascader';
import { createWorkflow } from '@/services/Workflow';
import AvatarUploader from '@/components/AvatarUploader';

export interface IWorkflowEditorProps  extends ModalProps {
  open?: boolean;
  workflow?: WorkflowItem;
}

export const MAX_FILE_SIZE_LIMIT = 10 * 1024 * 1024;

const DEFAULT_PARAM_NODE_TYPE = {
  KSampler: 1,
  EmptyLatentImage: 1,
  CLIPTextEncode: 1,
  LoadImage: 1,
  Base64ImageInput: 1,
  'Seed Generator': 1,
  BatchPromptScheduleLatentInput: 1,
  'Text box': 1,
  ImagePadForOutpaint: 1,
  'KSampler (Efficient)': 1,
  'CR Upscale Image': 1,
  ListSunoSongs: 1,
  GenSong: 1,
};

const DEFAULT_CHECKPOINT_NODE_TYPE = {
  CheckpointLoaderSimple: 1,
  ImageOnlyCheckpointLoader: 1,
  'Efficient Loader': 1,
};

const DEFAULT_LORA_NODE_TYPE = {
  LoraLoader: 1,
};

const DEFAULT_OUTPUT_NODE_TYPE = {
  SaveImage: 1,
  PreviewImage: 1,
  Base64ImageOutput: 1,
  SaveAnimatedWEBP: 1,
  VHS_VideoCombine: 1,
  Base64AnimatedWEBP: 1,
  ListSunoSongs: 1,
  GenSong: 1,
};

export interface IParamItemCompProps {
  options?: DefaultOptionType[];
  onDelete?: (index: number) => void;
  paramNode?: ParamNode;
  onChange?: (paramNode: ParamNode) => void;
}

const ParamItemComp: React.FC<IParamItemCompProps> = (props: IParamItemCompProps, key) => {

  const { options, paramNode, onDelete, onChange } = props;

  return <div key={key} className={style.paramItem}>
    <Cascader 
      style={{ minWidth: 375 }} 
      options={options} 
      placeholder="选择参数"
      defaultValue={[paramNode?.nodeId, paramNode?.paramKey]}
      onChange={(value) => {
        console.log('value: ', value);
        const [nodeId, paramKey] = value || [];
        onChange?.({
          ...paramNode,
          nodeId: nodeId as number,
          paramKey: paramKey as string,
        });
      }}
    />
    <Input placeholder="参数别名" value={paramNode?.key} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          key: e.target.value,
        });
      }}
    />
    <Input placeholder="参数名称" value={paramNode?.name} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          name: e.target.value,
        });
      }}
    />
    <Input placeholder="参数描述" value={paramNode?.description} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          description: e.target.value,
        });
      }}
    />
    <Input placeholder="参数默认值" value={paramNode?.defaultValue} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          defaultValue: e.target.value,
        });
      }}
    />
    <Button type="text" onClick={onDelete?.bind(null, paramNode)}><DeleteOutlined /></Button>
  </div>

}

export interface ICheckPointSelector {
  checkpointNodes: ComfyNode[],
  nodeDefs: NodeDefs,
  value?: ComfyNode[];
  onChange?: (value: ComfyNode[]) => void;
  type?: 'checkpoint' | 'lora';
}

const CheckPointSelector: React.FC<ICheckPointSelector> = (props) => {
  const { checkpointNodes, nodeDefs, value = [], onChange, type } = props;
  const paramKey = type === 'checkpoint' ? 'ckpt_name' : 'lora_name';

  const handleSelectChange = (index: number, checkpointNode: ComfyNode, selectedValue) => {
    const newValue = [ ...value ];
    checkpointNode.inputs[paramKey] = selectedValue;
    newValue[index] = checkpointNode;
    onChange?.(newValue);
  };
  
  return <div className={style.modelItem}>
    {
      checkpointNodes.map((checkpointNode, index) => {
        const def: NodeDef = nodeDefs[checkpointNode.class_type];
        return <div key={index} className={style.modelSubItem}>
          {`${checkpointNode.nodeId}-${checkpointNode.class_type}`}
          <Select options={def.input.required?.[paramKey]?.[0]?.map(item => {
              return {
                label: item,
                value: item,
              }
            })} 
            style={{ width: 302 }} 
            placeholder="选择模型" 
            value={value?.[index]?.inputs?.[paramKey]} 
            onChange={handleSelectChange.bind(null, index, checkpointNode)}
          />
        </div>
      })
    }
  </div>
};

export interface IParamSelectionCompProps {
  paramSelection: DefaultOptionType[];
  value?: Record<string, ParamNode>;
  onChange?: (value: Record<string, ParamNode>) => void;
  contentTpl?: Record<string, ComfyNode>;
}

const ParamSelectionComp: React.FC<IParamSelectionCompProps> = (props: IParamSelectionCompProps) => {

  const { value = {}, onChange, paramSelection: propsParamSelection, contentTpl } = props;
  const [paramNodes, setParamNodes] = useState<ParamNode[]>(Object.values(value));
  const [paramSelection, setParamSelection] = useState<DefaultOptionType[]>(propsParamSelection);
  const updateParamSelection = (propsParamSelection, newValue) => {
    setParamSelection(propsParamSelection.map(item => {
      item.children = item?.children?.map(paramNode => {
        let _key = `${paramNode.value}${item.value}`
        if (paramNode.value === 'image') {
          _key = `base64_image${item.value}`;
        }
        const disabled = !!newValue[_key];
        return {
          ...paramNode,
          disabled,
        }
      });
      return item;
    }));
  }
  useEffect(() => {
    if (!propsParamSelection?.length) return;
    if (value && Object.keys(value).length) {
      updateParamSelection(propsParamSelection, value);
    } else {
      setParamSelection(propsParamSelection);
    }
  }, [value, propsParamSelection]);

  useEffect(() => {
    if (value && Object.keys(value).length && !paramNodes.length) {
      setParamNodes(Object.values(value));
    }
  }, [paramNodes.length, value]);

  const appendParam = () => {
    const lastParamNode = paramNodes[paramNodes.length - 1];
    if (paramNodes.length && !lastParamNode?.nodeId) {
      message.error('请先填写上一个参数');
      return;
    }
    const newParamNodes = paramNodes.slice();
    newParamNodes.push({});
    setParamNodes(newParamNodes);
  }

  const onDelete = (paramNode) => {
    const newParamNodes = paramNodes.filter(item => item.key !== paramNode.key);
    setParamNodes(newParamNodes);
    delete value[paramNode.key];
    updateParamSelection(propsParamSelection, value);
    console.log('newParamNodes: ', newParamNodes);
  }

  if (!paramSelection.length) {
    return null;
  }

  if (!paramNodes.length) {
    return <Button onClick={appendParam} style={{ width: '100%' }} type="dashed">添加参数</Button>;
  }

  return <div className={style.paramGroup}>
    {
      paramNodes.map((paramNode, index) => {
        return <ParamItemComp
          key={index} 
          paramNode={paramNode}
          options={paramSelection} 
          onDelete={onDelete}
          onChange={(paramNode) => {
            const newParamNodes = paramNodes.slice();
            newParamNodes[index] = paramNode;
            setParamNodes(newParamNodes);
            const newValue = {};
            newParamNodes.forEach(item => {
              if (item.paramKey === 'image') {
                item.paramKey = 'base64_image';
              }
              item.key = `${item.paramKey}${item.nodeId}`;
              console.log('item.paramKey: ', item.paramKey);
              item.defaultValue = contentTpl?.[item.nodeId]?.inputs?.[item.paramKey];
              if (typeof item.defaultValue === 'string') {
                item.paramType = 'String';
              } else if (typeof item.defaultValue === 'number') {
                item.paramType = 'Number';
              } else if (Array.isArray(item.defaultValue)) {
                item.paramType = 'Array';
              }
              if (item.paramKey === 'base64_image') {
                item.paramType = 'Image';
              }
              newValue[item.key] = item;
            });
            onChange?.(newValue);
          }}
        />
      })
    }
    <Button onClick={appendParam} style={{ width: '100%' }} type="dashed">添加参数</Button>
  </div>
}

const hasDuplicates = (array) => {
  return (new Set(array)).size !== array.length;
};

const InviteCodeInputs = (props) => {
  const { value, onChange } = props;
  const [ inviteCodes, setInviteCodes ] = useState<string[]>(value || ['']);
  useEffect(() => {
    setInviteCodes(value || ['']);
  }, [value]);

  const appendParam = () => {
    let _inviteCodes = inviteCodes;
    if (typeof inviteCodes !== 'object') {
      _inviteCodes = [];
    }
    if (_inviteCodes.some(item => !item)) {
      message.error('邀请码不能为空');
      return;
    }
    if (_inviteCodes.length >= 10) {
      message.error('邀请码不能超过10个');
      return;
    }
    if (_inviteCodes.some(item => item.length > 8)) {
      message.error('邀请码不能超过8位');
      return;
    }
    if (_inviteCodes.some(item => item.length < 4)) {
      message.error('邀请码不能少于4位');
      return;
    }
    if (hasDuplicates(_inviteCodes)) {
      message.error('邀请码不能重复');
      return;
    } 
    const newInviteCodes = _inviteCodes.slice();
    newInviteCodes.push('');
    setInviteCodes(newInviteCodes);
    onChange?.(newInviteCodes);
  }

  const onDelete = (index) => {
    const newInviteCodes = inviteCodes.slice();
    newInviteCodes.splice(index, 1);
    setInviteCodes(newInviteCodes);
    onChange?.(newInviteCodes);
  }

  return <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    {
      inviteCodes?.map?.((code, index) => {
        console.log('code: ', code);
        return <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <Input key={index} value={code} onBlur={(e) => {
            const _value = e.target.value;
            if (_value.length > 8) {
              message.error('邀请码不能超过8位');
              return;
            }
            console.log('inviteCodes: ', inviteCodes, _value);
            inviteCodes.filter(item => item === _value).length > 1 && message.error('邀请码不能重复');
          }} onChange={(e) => {
            const _value = e.target.value;

            if (_value.length > 8) {
              message.error('邀请码不能超过8位');
              return;
            }

            const newInviteCodes = inviteCodes.slice();
            newInviteCodes[index] = e.target.value;
            setInviteCodes(newInviteCodes);
            onChange?.(newInviteCodes);
          }} />
          <Button type="text" onClick={onDelete?.bind(null, index)}><DeleteOutlined /></Button>
        </div>
      })
    }
    <Button onClick={appendParam} style={{ width: '100%' }} type="dashed">添加邀请码</Button>
  </div>
}

const WorkflowPrivateSwitch = (props) => {

  const { value, onChange, ...restProps } = props;

  return <div>
    <Switch {...restProps} checked={value === 'N'} onChange={(value) => {
      onChange?.(value ? 'N' : 'Y');
    }} />
  </div>
}

const WorkflowEditor: React.FC<IWorkflowEditorProps> = (props: IWorkflowEditorProps) => {

  const { open, workflow, ...modalProps } = props;
  const [ paramSelection, setParamSelection ] = useState<DefaultOptionType[]>([]);
  const [ checkpointNodes, setCheckpointNodes ] = useState<ComfyNode[]>([]);
  const [ loRANodes, setLoRANodes ] = useState<ComfyNode[]>([]);
  const [ outputNodes, setOutputNodes ] = useState<ComfyNode[]>([]);
  const [ nodeDefs, setNodeDefs ] = useState<NodeDefs>({});
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState<FieldData[]>();
  const [isPrivate, setIsPrivate] = useState('N' as 'Y' | 'N');
  
  const clear = useCallback(() => {
    setParamSelection([]);
    setCheckpointNodes([]);
    setLoRANodes([]);
    setOutputNodes([]);
    form.resetFields();
    setFormFields([]);
  }, [form]);

  useEffect(() => {
    const { name, code, description, cover, contentTpl, paramTpl, outputTpl, isPrivate } = workflow || {};
    if (!contentTpl) return;
    api.getNodeDefs().then((response) => {
      setNodeDefs(response);
      const nodeIds = Object.keys(contentTpl);
      const paramSelection: DefaultOptionType[] = [];
      const outputNodes: ComfyNode[] = [];
      const checkpointNodes: ComfyNode[] = [];
      const loRANodes: ComfyNode[] = [];
      nodeIds?.forEach((nodeId) => {
        const node: ComfyNode = contentTpl[nodeId];
        const { inputs, class_type } = node;
        if (class_type in DEFAULT_PARAM_NODE_TYPE) {
          const paramItem: DefaultOptionType = {
            value: nodeId,
            label: `${nodeId} - ${node.class_type}`,
            children: []
          };
          for (const input in inputs) {
            switch (typeof(inputs[input])) {
              case 'string':
                paramItem?.children?.push({
                  label: input,
                  value: `${input}`,
                });
                break;
              case 'number':
                paramItem?.children?.push({
                  label: input,
                  value: `${input}`,
                });
                break;
              default:
                console.log('Unknown input type: ', input, typeof(inputs[input]));
                break;
            }
          }
          paramSelection.push(paramItem);
          setParamSelection(paramSelection);
        } else if (class_type in DEFAULT_CHECKPOINT_NODE_TYPE) {
          checkpointNodes.push({
            ...node,
            nodeId,
          });
          setCheckpointNodes(checkpointNodes);
        } else if (class_type in DEFAULT_LORA_NODE_TYPE) {
          loRANodes.push({
            ...node,
            nodeId,
          });
          setLoRANodes(loRANodes);
        } else {
          console.log('Unknown node type: ', class_type);
        }
        if (class_type in DEFAULT_OUTPUT_NODE_TYPE) {
          console.log('class_type: ', class_type, DEFAULT_OUTPUT_NODE_TYPE);
          outputNodes.push({
            ...node,
            nodeId,
          });
          setOutputNodes(outputNodes);
        }

      });
      const fields: FieldData[] = [
        {
          name: 'name',
          value: name,
        },
        {
          name: 'code',
          value: code,
        },
        {
          name: 'description',
          value: description,
        },
        {
          name: 'cover',
          value: cover,
        },
        {
          name: 'paramTpl',
          value: paramTpl,
        },
        {
          name: 'outputTpl',
          value: outputTpl?.images?.nodeId,
        },
        {
          name: 'checkpoints',
          value: checkpointNodes,
        },
        {
          name: 'loras',
          value: loRANodes,
        },
        {
          name: 'isPrivate',
          value: isPrivate,
        },
        {
          name: 'inviteCodes',
          value: JSON.parse(workflow?.inviteCodes || '[]'),
        }
      ];
      setIsPrivate(isPrivate || 'N' as 'Y' | 'N');
      setFormFields(fields);
    });
    
  }, [workflow, open]);


  const handleOk = async (e) => {
    try {
      const validRes = await form.validateFields();
      const { checkpoints, loras, inviteCodes, ...values } = validRes;
      const contentTpl = workflow?.contentTpl || {};
      checkpoints?.forEach(item => {
        contentTpl[item.nodeId] = item;
      });
      loras?.forEach(item => {
        contentTpl[item.nodeId] = item;
      });
      const outputTpl = {
        'images': {
          nodeId: values.outputTpl,
          paramKey: 'images',
          paramType: 'Array'
        }
      };
      const realWorkflow = {
        ...workflow,
        ...values,
        contentTpl,
        outputTpl,
      };
      if (typeof inviteCodes === 'object') {
        realWorkflow.inviteCodes = JSON.stringify(inviteCodes);
      }
      await createWorkflow({
        ...realWorkflow
      });
      modalProps?.onOk?.(e);
    } catch (error) {
      console.error('error: ', error);
    }
  }

  const handleCancel = (e) => {
    clear();
    modalProps?.onCancel?.(e);
  }

  return <Modal
    title={<h1>编辑AI应用 {workflow?.name}</h1>}
    open={open} 
    {...modalProps}
    onOk={handleOk}
    onCancel={handleCancel}
    className={style.WorkflowEditor}
    >
      <Form 
        labelCol={{ span: 2 }}
        form={form}
        fields={formFields}
        onFieldsChange={(changedFields) => {
          if (changedFields[0]?.name[0] === 'isPrivate') {
            setIsPrivate(changedFields[0].value);
          }
        }}
      >
        <div className={style.workflowEditWrapper}>
          <div className={style.modelSection}>
            <h2>基本信息</h2>
            <div>
              <Form.Item name="name" label="应用名称" rules={[{ required: true }]}>
                <Input placeholder="应用名称" />
              </Form.Item>
              <Form.Item name="code" label="应用别名" rules={[{ 
                required: true, 
                pattern: new RegExp(/^[a-zA-Z0-9_-]{6,15}$/),
                message: "应用别名必须是6-15位英文字符"
              }]}
              >
                <Input 
                  placeholder="应用唯一编码，全英文"
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="description" label="应用描述">
                <Input.TextArea placeholder="应用描述" />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="isPrivate" label="是否公开" rules={[{ required: false }]}>
                <WorkflowPrivateSwitch />
              </Form.Item>
            </div>
            { isPrivate === 'Y' ?  <div>
              <Form.Item name="inviteCodes" label="邀请码" rules={[{ required: false }]}>
                <InviteCodeInputs />
              </Form.Item>
            </div> : null}
            <div>
              <Form.Item name="cover" label="封面图" rules={[{ required: false }]}>
                <AvatarUploader enableCrop />
              </Form.Item>
            </div>
          </div>
          { checkpointNodes?.length || loRANodes?.length ? <div className={style.modelSection}>
            <div>
              <h2>模型配置</h2>
            </div>
            <div className={style.modelSelection}>
              { checkpointNodes?.length ? <div className={style.modelGroup}>
                <h4>CheckPoint</h4>
                <Form.Item name="checkpoints" label="" rules={[{ required: true }]}>
                  <CheckPointSelector type='checkpoint' checkpointNodes={checkpointNodes} nodeDefs={nodeDefs} />
                </Form.Item>
              </div> : null }
              { loRANodes?.length ? <div className={style.modelGroup}>
                <h4>LoRA</h4>
                <Form.Item name="loras" label="" rules={[{ required: true }]}>
                  <CheckPointSelector type='lora' checkpointNodes={loRANodes} nodeDefs={nodeDefs} />
                </Form.Item>
              </div>: null }
            </div>
          </div> : null }

          <div className={style.paramSection}>
            <div>
              <h2>参数配置</h2>
            </div>
            <div className={style.paramGroup}>
              <Form.Item name="paramTpl" label="" rules={[{ required: true }]}>
                <ParamSelectionComp contentTpl={workflow?.contentTpl} paramSelection={paramSelection} />
              </Form.Item>
            </div>
          </div>

          <div className={style.outputSection}>
            <div>
              <h2>结果配置</h2>
            </div>
            <div className={style.paramGroup}>
              <Form.Item name="outputTpl" label="" rules={[{ required: true }]}>
                <Select allowClear style={{width: 256}} options={outputNodes.map(item => {
                  return {
                    label: `${item.nodeId} - ${item.class_type}`,
                    value: item.nodeId,
                  }
                })} />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
  </Modal>
}

export default WorkflowEditor;