import { isChineseDomain } from "@/utils";
const isChineseDomainFlag = isChineseDomain();

export default {
  /**
   * 素材
   */
  StickerLabel: 'Sticker',
  Use: 'USE',
  Home: 'Home',
  Design: 'Design',
  Train: 'Train',
  SignIn: 'Log in',
  SignUp: 'Get started for free',
  Contact: 'Contact',
  ComingSoon: 'Coming soon~',
  Email: 'Email',
  DigitalTwin: 'DigitalTwin',
  Resample: 'Resample',
  Rembg: 'Rembg',
  ToolBox: 'ToolBox',
  AIPaint: 'AIPaint',
  Profile: 'Profile',
  MyPage: 'HomePage',
  Developer: 'Developer',
  AIWorkflow: 'AI Workflow',
  AIAppsBuilder: 'AIAppsBuilder',
  Member: 'Member',
  MyWorkflow: 'My Workflow',
  EditorPick: 'Editor\'s Pick',
  UploadWorkflow: 'Upload Workflow',
  UserCenter: 'User Center',
  Logout: 'Sign Out',
  AIBoostArt: 'AI Boosting Artistry',
  BornForDesigner: 'Born for the Next-Gen Designers.',
  NoWorkflow: 'No Workflow Yet',
  TXT2IMG: 'TXT2IMG',
  openApp: 'Open App',
  editApp: 'Edit App',
  FreeTrial: 'Free Trial',
  Discount: 'Discount',
  generate: 'Generate',
  generating: 'Generating...',
  generateFailed: 'Generate Failed',
  workflow: {
    app: {
      left: 'Left',
      MCoin: 'MCoin',
      playground: 'Playground',
      api: 'Open API',
      comingSoon: 'Coming Soon',
      cost: 'Cost',
    }
  },
  home: {
    AIWorkflow: 'AI Workflow',
    AIWorkflowDesc: 'AI Workflow is a tool that helps you to create AI applications without coding.',
    AIPainting: 'AI Painting',
    AIPaintingDesc: 'AI Painting WebUI, save painting parameters with one click.',
    malette: 'Malette',
    product: 'Product',
    getStarted: 'Get Started',
    connect: 'Connect',
    aiPainting: 'AI Painting',
    aiWorkflow: 'AI Workflow',
    styleFusion: 'Style Fusion',
    stickers: 'Stickers',
    tryOn: 'Try On',
    imageTranslate: 'Manga Translation',
    pricing: 'Pricing',
    Tutorials: 'Tutorials',
    FAQ: 'FAQ',
    comingSoon: 'Coming Soon',
    sloganTitle: 'Pick·Upload·Create, one-click to launch your AI journey with <bold>{{appName}}</bold>',
    appName: isChineseDomainFlag ? 'Malette.Art' : 'Malette.AI',
    textSticker: 'Text Sticker',
    aiHair: "AI Hair Color",
    stickerMaker: "Sticker Maker",
  },
  loginForm: {
    login: 'Log in',
    email: 'Email',
    password: 'Password',
    remember: 'Remember me',
    forget: 'Forget password?',
    noAccount: 'No account?',
    signUp: 'Get started for free',
    loginFailed: 'Login failed',
    welcome: 'Welcome to Malette.Art',
    signInOrSignUp: 'Sign in/up',
    sendVerifyCode: 'Send code',
    sendAgain: 'Send again',
    codeInvalid: 'Invalid code',
    emailInvalid: 'Invalid email',
    paramInvalid: 'Invalid parameter',
    footer: 'Login to agree <term>Terms of Service</term> and <privacy>Privacy Policy</privacy>, account will be created if you don\'t have one.',
  },
  OpenAPI: {
    APITokenDescription: 'API Token, get it from `User Center -> Developer` page',
  },
  signAgreementDialog: {
    title: 'Sign Agreement',
    content: 'Please read and agree to the <term>Terms of Service</term> and <privacy>Privacy Policy</privacy>, click agree to indicate that you have read and agreed, the agreement will take effect when you use the service.',
    cancel: 'Cancel',
    agree: 'Agree',
  },
  PaymentModal: {
    title: 'Recharge Prompt',
    content: 'M Coin is not enough, please recharge before using.',
    cancel: 'Cancel',
    agree: 'Go to recharge',
  },
};
